<template>
  <div>
    <h2 class="text-uppercase page-title mt-2">
      {{ getStepTitle($route.params.step) }}
    </h2>
    <p class="mt-4 mb-6 text--lighttext">
      {{ getStepDescription($route.params.step) }}
    </p>
    <component :is="$route.params.step" ref="step" @update="updateOnboardings" />
  </div>
</template>

<script>
import Restaurants from "./Restaurants.vue"
import Payments from "./Payments.vue"
import Branding from "./Branding.vue"
import Settings from "./Settings.vue"
import Charges from "./Charges.vue"
import Deliverect from "./Deliverect.vue"
import { mapActions, mapState } from "vuex"

export default {
  name: "onboarding-step",
  components: {
    Restaurants,
    Payments,
    Branding,
    Settings,
    Charges,
    Deliverect,
  },
  computed: {
    ...mapState(["onboardings", "currentOnboardingIndex"]),
  },
  methods: {
    ...mapActions(["updateOnboardings"]),
    async submitData() {
      await this.$refs.step.submitData()
    },
    getStepTitle(step) {
      return this.onboardings[this.currentOnboardingIndex]?.steps[step]?.title
    },
    getStepDescription(step) {
      return (
        this.onboardings[this.currentOnboardingIndex]?.steps[step]?.description ?? ""
      )
    },
  },
}
</script>
