<template>
  <v-footer fixed elevation="3" class="fixed-btn-container py-4">
    <v-row align="center">
      <v-col cols="4">
        <v-btn
          :disabled="isOnFinishStep"
          color="primary"
          class="fixed-btn"
          :to="{ params: { step: getPreviousStep() } }"
        >
          Back
        </v-btn>
      </v-col>
      <v-col cols="5" justify="center">
        <v-progress-linear :value="progressPercent" color="green" height="25">
          <template v-slot:default>
            <strong>{{ currentStepNumber }} / {{ numberOfSteps }}</strong>
          </template>
        </v-progress-linear>
      </v-col>
      <v-col cols="3">
        <v-btn
          v-if="!isOnFinishStep"
          color="primary"
          :disabled="!next"
          @click="submitStepAndContinue()"
        >
          {{ buttonLabel }}
        </v-btn>
        <v-btn
          v-else-if="
            ($route.params.step === 'finish' || progressPercent === 100) &&
            !isOnboardingCompleted
          "
          color="primary"
          @click="goToNextOnboarding()"
        >
          To next onboarding
        </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"

export default {
  props: {
    next: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOnboardingCompleted: false,
    }
  },
  computed: {
    ...mapState(["currentUserRole", "onboardings", "currentOnboardingIndex"]),
    currentStepNumber() {
      return (
        this.onboardings[this.currentOnboardingIndex]?.steps[this.$route.params.step]
          .index + 1
      )
    },
    numberOfSteps() {
      return Object.keys(this.onboardings[this.currentOnboardingIndex]?.steps).length
    },
    progressPercent() {
      return (this.currentStepNumber / this.numberOfSteps) * 100
    },
    buttonLabel() {
      return this.getNextStep() !== "finish" ? "Save & Continue" : "Submit & Finish"
    },
    nextStep() {
      return this.getNextStep()
    },
    isOnFinishStep() {
      return this.$route.params.step === "finish"
    },
  },
  watch: {
    "$route.params.step": {
      handler(newStep) {
        if (newStep === "finish" && this.currentUserRole === "Manager") {
          this.updateOnboardingCompleted()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    ...mapActions(["updateCurrentOnboardingStep"]),
    ...mapMutations(["setCurrentOnboardingIndex"]),
    async submitStepAndContinue() {
      this.$emit("submit")

      await this.updateCurrentOnboardingStep({
        step: this.$route.params.step,
        completed: true,
        type: this.currentUserRole === "Manager" ? "restaurants" : "organization",
        id: this.onboardings[this.currentOnboardingIndex].restaurantId,
      })

      this.updateOnboardingCompleted()

      const nextStep = this.getNextStep()

      if (nextStep === "finish" && this.isOnboardingCompleted) {
        this.$router.push({ name: "Home", query: { onboardingDone: 1 } })
      } else {
        this.$router.push({
          params: { step: this.getNextStep() },
        })
      }
    },
    getNextStep() {
      const currentStep = this.$route.params.step
      const currentIndex =
        this.onboardings[this.currentOnboardingIndex]?.steps[currentStep].index
      return (
        Object.values(this.onboardings[this.currentOnboardingIndex]?.steps).find(
          obj => obj.index === currentIndex + 1
        )?.name ?? "finish"
      )
    },
    getPreviousStep() {
      const currentStep = this.$route.params.step
      const currentIndex =
        this.onboardings[this.currentOnboardingIndex]?.steps[currentStep].index

      return (
        Object.values(this.onboardings[this.currentOnboardingIndex]?.steps).find(
          obj => obj.index === currentIndex - 1
        )?.name ?? null
      )
    },
    async goToNextOnboarding() {
      this.setCurrentOnboardingIndex(this.currentOnboardingIndex + 1)
      this.$router.push({
        params: { step: null },
      })
    },
    async updateOnboardingCompleted() {
      this.isOnboardingCompleted = this.onboardings.every(onboarding =>
        Object.keys(onboarding.steps).every(step => onboarding.steps[step].completed)
      )
    },
  },
}
</script>
