<template>
  <v-card outlined class="mb-8">
    <v-card-subtitle>
      Would you like to use Deliverect to publish a menu for all your restaurants?
    </v-card-subtitle>
    <v-row class="mt-3">
      <v-col cols="12">
        <v-radio-group v-model="useDeliverect" row>
          <v-radio label="Yes" :value="true"></v-radio>
          <v-radio label="No" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12">
        <span v-if="useDeliverect">
          Please go to
          <a target="_blank" href="https://frontend.deliverect.com/">Deliverect</a>
          and add NextGen Kitchens as a POS provider. Then, please sync your
          products, build a menu on Deliverect, and then publish the menu to NextGen
          Kitchens.
        </span>
      </v-col>
    </v-row>
    <onboarding-footer next />
  </v-card>
</template>

<script>
import OnboardingFooter from "../OnboardingFooter.vue"

export default {
  name: "onboarding-deliverect",
  components: { OnboardingFooter },
  data() {
    return {
      useDeliverect: false,
    }
  },
}
</script>
