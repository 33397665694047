<template>
  <div>
    <div v-if="currentUserRole === 'Admin'">
      <v-card outlined class="mb-8" style="background: #eee !important" light>
        <v-card-subtitle>{{ settings.organizationName }} Logo</v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <label class="px-0 text-caption text-center">App Logo</label>
              <custom-image-upload
                class="mt-4"
                name="brandLogo"
                :imageUrl="orgLogoUrl"
                :location="`Organization/hero`"
                small
                @imageProcessed="orgLogoUrl = $event"
              />
            </v-col>
            <v-col cols="8">
              <v-row>
                <v-col :cols="hasWhiteLogo ? 6 : 12">
                  <label class="px-0 text-caption mb-2">Logo Settings</label>
                  <p class="my-4">Is your logo clearly visible on the left?</p>
                  <v-switch
                    v-model="hasWhiteLogo"
                    :label="hasWhiteLogo ? 'No' : 'Yes'"
                  />
                  <v-alert v-if="hasWhiteLogo" type="info" outlined>
                    Your logo will need a dark version for use on light backgrounds.
                  </v-alert>
                  <p class="my-4 mt-8">
                    Does your logo have a transparent background?
                  </p>
                  <v-switch
                    v-model="transparentLogo"
                    :label="transparentLogo ? 'Yes' : 'No'"
                  />
                  <v-alert v-if="transparentLogo" type="info" outlined>
                    We will ensure your logo looks good on any background.
                  </v-alert>
                </v-col>
                <v-col v-if="hasWhiteLogo" cols="6" style="overflow: hidden">
                  <label class="px-0 text-caption text-center">Dark App Logo</label>
                  <custom-image-upload
                    class="mt-4"
                    name="brandLogoDark"
                    :imageUrl="orgLogoDarkUrl"
                    :location="`Organization/heroDark`"
                    small
                    @imageProcessed="orgLogoDarkUrl = $event"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card outlined class="mb-8">
        <v-card-subtitle>Brand Colors</v-card-subtitle>
        <v-card-text>
          <v-row class="mt-0">
            <v-col cols="6">
              <color-picker v-model="color" label="Primary Brand Color" />
            </v-col>
            <v-col cols="6">
              <color-picker v-model="textColor" label="Primary Brand Text Color" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="settings.singleBrandEnabled || currentUserRole === 'Manager'">
      <v-card outlined class="mb-8">
        <v-card-subtitle class="mb-4 mr-2">
          Restaurant Branding
          <branding-hint />
        </v-card-subtitle>
        <v-card-text>
          <v-row justify="center" class="mt-0">
            <v-col cols="4" class="mt-2 pb-0" align="center">
              <v-card-subtitle class="mb-4">Logo Image</v-card-subtitle>
              <custom-image-upload
                name="logo"
                :imageUrl="restaurantLogoUrl"
                :location="`${currentRestaurant?.id}/logo`"
                small
                @imageProcessed="restaurantLogoUrl = $event"
              />
            </v-col>
            <v-col cols="8" class="mt-2 pb-0" align="center">
              <v-card-subtitle class="mb-4">Hero Image</v-card-subtitle>
              <custom-image-upload
                name="hero"
                :imageUrl="restaurantHeroUrl"
                :location="`${currentRestaurant?.id}/hero`"
                @imageProcessed="restaurantHeroUrl = $event"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        v-if="restaurantHeroUrl"
        flat
        color="light-background"
        class="mt-4 pb-4"
        style="border-radius: 20px"
      >
        <v-card-title class="mb-4">Preview</v-card-title>
        <v-card-subtitle class="text--lighttext">Desktop / Kiosk:</v-card-subtitle>
        <v-card-text class="mb-4 hero-preview-wrapper">
          <v-img
            class="hero-preview"
            min-width="900"
            max-width="900"
            :src="restaurantHeroUrl"
          />
          <v-img
            width="120"
            height="120"
            class="logo-preview"
            :src="restaurantLogoUrl"
          />
        </v-card-text>
        <v-card-subtitle class="text--lighttext">Mobile:</v-card-subtitle>
        <v-card-text class="mb-4 hero-preview-wrapper mobile">
          <v-img
            class="hero-preview"
            min-width="500"
            max-width="500"
            :src="restaurantHeroUrl"
          />
          <v-img
            width="120"
            height="120"
            class="logo-preview"
            :src="restaurantLogoUrl"
          />
        </v-card-text>
      </v-card>
    </div>
    <onboarding-footer :next="isValid" @submit="submitData" />
  </div>
</template>

<script>
import OnboardingFooter from "../OnboardingFooter.vue"
import ColorPicker from "@/AuthenticatedContent/shared/forms/ColorPicker.vue"
import CustomImageUpload from "@/AuthenticatedContent/shared/CustomImageUpload.vue"
import BrandingHint from "./BrandingHint.vue"
import { mapGetters, mapState, mapActions } from "vuex"

export default {
  name: "onboarding-branding",
  components: { OnboardingFooter, ColorPicker, CustomImageUpload, BrandingHint },
  data() {
    return {
      restaurantHeroUrl: "",
      restaurantLogoUrl: "",
      orgLogoUrl: "",
      orgLogoDarkUrl: "",
      transparentLogo: false,
      hasWhiteLogo: false,
      color: "#000000",
      textColor: "#000000",
    }
  },
  computed: {
    ...mapState(["firebaseRefs", "settings", "restaurants", "currentUserRole"]),
    ...mapGetters(["currentOnboarding"]),
    currentRestaurant() {
      if (this.settings.singleBrandEnabled) {
        return this.restaurants[0]
      }
      return this.restaurants.find(
        restaurant => restaurant.id === this.currentOnboarding.restaurantId
      )
    },
    isValid() {
      const isAdminBrandingComplete =
        this.color?.length &&
        this.textColor?.length &&
        this.orgLogoUrl?.length &&
        (!this.hasWhiteLogo || this.orgLogoDarkUrl)
      const isManagerBrandingComplete =
        this.restaurantHeroUrl?.length && this.restaurantLogoUrl?.length
      const isBrandingComplete = isAdminBrandingComplete && isManagerBrandingComplete

      return this.settings.singleBrandEnabled
        ? !!isBrandingComplete
        : this.currentUserRole === "Admin"
        ? !!isAdminBrandingComplete
        : !!isManagerBrandingComplete
    },
  },
  mounted() {
    let restaurant
    if (this.settings.singleBrandEnabled) {
      restaurant = this.restaurants[0]
    } else {
      restaurant = this.restaurants.find(
        restaurant => restaurant.id === this.currentRestaurant?.id
      )
    }
    this.color = this.settings["organizationColor"] || "#000000"
    this.textColor = this.settings["organizationTextColor"] || "#000000"
    this.orgLogoUrl = this.settings["organizationLogo"] || ""
    this.orgLogoDarkUrl = this.settings["organizationLogoDark"] || ""
    this.hasWhiteLogo = this.orgLogoDarkUrl?.length > 0
    this.transparentLogo = this.settings["transparentLogo"] || false
    this.restaurantHeroUrl = restaurant?.images?.hero || ""
    this.restaurantLogoUrl = restaurant?.images?.logo || ""
  },
  methods: {
    ...mapActions([
      "updatePublicSettings",
      "updateOrganizationSettings",
      "updateRestaurants",
    ]),
    async submitData() {
      if (this.settings.singleBrandEnabled || this.currentUserRole === "Admin") {
        const newSettings = {
          organizationColor: this.color,
          organizationTextColor: this.textColor,
          organizationLogo: this.orgLogoUrl,
          organizationLogoDark: this.orgLogoDarkUrl,
          transparentLogo: this.transparentLogo,
        }
        await this.updatePublicSettings(newSettings)
      }

      if (this.settings.singleBrandEnabled || this.currentUserRole === "Manager") {
        const restaurant = this.restaurants.find(
          restaurant => restaurant.id === this.currentRestaurant.id
        )
        await this.updateRestaurants({
          ...restaurant,
          images: {
            hero: this.restaurantHeroUrl,
            logo: this.restaurantLogoUrl,
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.hero-preview-wrapper {
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    pointer-events: none;
    background: var(--v-light-background-base);
    position: absolute;
    max-width: 900px;
    width: 100%;
    height: 90px;
    left: 16px;
    bottom: -45px;
    transform: skewY(3deg);
    z-index: 2;
    border-left: solid 15px var(--v-primary-base);
    border-right: solid 15px var(--v-primary-base);
  }
  .hero-preview {
    max-height: 300px;
    z-index: 1;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border: solid 15px var(--v-primary-base);
    border-bottom: none;
  }
  .logo-preview {
    border-radius: 20px;
    position: absolute;
    left: 50px;
    bottom: 10px;
    z-index: 3;
  }
  &.mobile {
    &:before {
      max-width: 500px;
    }
    &:after {
      content: "";
      pointer-events: none;
      background: #000;
      position: absolute;
      width: 100px;
      height: 30px;
      left: 220px;
      top: 20px;
      z-index: 3;
      border-radius: 20px;
    }
  }
}
</style>
