<template>
  <div class="mx-auto pt-4">
    <div
      v-if="!$route.params.step"
      class="d-flex flex-column align-center justify-center"
    >
      <h2 class="text-uppercase page-title mb-4 mt-2">
        {{ getOnboardingTitle() }}
      </h2>
      <v-card outlined class="mt-8">
        <v-alert
          v-if="onboardingCompleted"
          type="success"
          text
          min-width="500"
          class="pl-6 mb-1"
        >
          <v-card-title class="pl-6 py-1">
            Onboarding completed successfully!
          </v-card-title>
        </v-alert>
        <v-card-subtitle v-else>
          Get started with onboarding. Follow the steps below to complete the
          onboarding process.
        </v-card-subtitle>
        <onboarding-panel
          v-for="(step, i) in onboardingSteps"
          :key="i"
          :step="step"
          :active="i === activeStepIndex"
          :index="i"
          :class="{ 'mb-1': i < onboardingSteps.length - 1 }"
        />
      </v-card>
      <p
        v-if="onboardingCompleted && !menuContentExists"
        class="ma-6"
        style="max-width: 500px"
      >
        You will need to create your menu content after the onboarding. You can do
        that using the navigation menu on the left.
      </p>
      <v-btn
        v-if="allOnboardingsCompleted"
        color="success"
        x-large
        min-width="500"
        class="mt-6"
        :to="
          menuContentExists
            ? { name: 'Home' }
            : { name: 'Menu', params: { tab: 'items' } }
        "
      >
        Proceed to Restaurant Manager
      </v-btn>
    </div>
    <div v-else class="mx-auto" style="max-width: 1000px">
      <steps ref="steps" />
    </div>
  </div>
</template>

<script>
import OnboardingPanel from "./OnboardingPanel.vue"
import Steps from "./Steps/index.vue"
import { mapState, mapGetters, mapActions } from "vuex"

export default {
  name: "onboarding",
  components: { OnboardingPanel, Steps },
  data() {
    return {
      currentStepStatus: false,
      onboardingSteps: [],
    }
  },
  computed: {
    ...mapState([
      "currentUserRole",
      "settings",
      "restaurants",
      "onboardings",
      "currentOnboardingIndex",
      "menuItems",
    ]),
    ...mapGetters(["currentOnboarding", "siteTitle"]),
    shouldSkipPayments() {
      return this.settings.paymentProvider === "square"
    },
    activeStepIndex() {
      return this.onboardingSteps.findIndex(step => !step.completed)
    },
    onboardingCompleted() {
      return this.onboardingSteps.every(step => step.completed)
    },
    allOnboardingsCompleted() {
      return this.onboardings.every(onboarding =>
        Object.values(onboarding.steps).every(step => step.completed)
      )
    },
    menuContentExists() {
      return this.menuItems.length > 0
    },
  },
  watch: {
    currentOnboarding: {
      handler() {
        if (this.shouldSkipPayments) {
          delete this.onboardings[this.currentOnboardingIndex]?.steps.payments
        }
      },
      deep: true,
      immediate: true,
    },
    "$route.params.step": {
      handler() {
        this.updateOnboardingSteps()
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.fetchOnboardings()
    this.updateOnboardingSteps()
  },
  methods: {
    ...mapActions(["fetchOnboardings"]),
    getOnboardingTitle() {
      let title = this.siteTitle + " Onboarding for "
      if (this.currentUserRole !== "Admin") {
        title += this.getRestaurantName(this.currentOnboarding.restaurantId)
      } else {
        title += this.settings.organizationName
      }
      return title
    },
    getRestaurantName(id) {
      return this.restaurants.find(restaurant => restaurant.id === id)?.name
    },
    updateOnboardingSteps() {
      this.onboardingSteps = Object.values(
        this.onboardings[this.currentOnboardingIndex]?.steps
      )
        .filter(step => !step.substep)
        .sort((a, b) => a.index - b.index)
    },
  },
}
</script>
