<template>
  <v-card outlined class="pb-8">
    <v-card-text>
      <div v-if="currentUserRole === 'Admin'">
        <div v-if="settings.singleBrandEnabled && !stripePlatformAccountSetup">
          <v-card-subtitle class="pa-0">
            Which provider would you like to use for your payments?
          </v-card-subtitle>
          <v-row class="mt-3">
            <v-col>
              <v-radio-group v-model="paymentProvider" row>
                <v-radio label="Stripe" value="stripe" />
                <v-radio label="Square" value="square" />
              </v-radio-group>
            </v-col>
          </v-row>
        </div>
        <div v-if="paymentProvider === 'stripe'">
          <v-img
            class="ml-n3 mb-8"
            width="100"
            :src="require('@/assets/logos/stripe.webp')"
            alt="Stripe Logo"
          />
          <div v-if="stripePlatformAccountSetup">
            <h3>Stripe Connected Successfully!</h3>
            <v-row class="mt-3">
              <v-col>
                <span>
                  Your Stripe account has been connected successfully. You will be
                  able to receive payments through Stripe
                  <span v-if="!settings.singleBrandEnabled">
                    and so will any associated restaurants after connecting with
                    Stripe.
                  </span>
                </span>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-card-subtitle class="pa-0">Setting up Stripe</v-card-subtitle>
            <v-row class="mt-3">
              <v-col>
                <p>Do you already have a Stripe account?</p>
                <v-radio-group v-model="hasStripeAccount" row>
                  <v-radio label="Yes" value="yes" />
                  <v-radio label="No" value="no" />
                </v-radio-group>
                <div v-if="hasStripeAccount.length">
                  <p>
                    Please set up your Stripe account so you can receive payments by
                    following the following steps:
                  </p>
                  <ol>
                    <li v-if="hasStripeAccount === 'yes'">
                      Click the button below to sign in to your existing Stripe
                      account.
                    </li>
                    <li>
                      After signing {{ (hasStripeAccount && "in") || "up" }}, if
                      there's a banner titled "Activate Account" at the top, press
                      that and follow the instructions there to activate your
                      account.
                    </li>
                    <li>
                      Once activated, please go to
                      <a
                        href="https://dashboard.stripe.com/settings/team?invite_shown=true"
                        target="_blank"
                      >
                        Settings > Team >
                        <strong>+ New Member</strong>
                      </a>
                      and add these 2 users, giving the role "Administrator":
                      <ul>
                        <li>it@nextgenkitchens.com</li>
                        <li>aidin@nextgenkitchens.com</li>
                      </ul>
                    </li>
                  </ol>

                  <v-btn
                    class="my-6 ml-3"
                    dark
                    color="#625aff"
                    :loading="loading"
                    :href="`https://dashboard.stripe.com/${
                      (hasStripeAccount === 'yes' && 'login') || 'register'
                    }`"
                    target="_blank"
                    @click="isValid = true"
                  >
                    + Connect to Stripe
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <div v-else-if="settings.singleBrandEnabled && paymentProvider === 'square'">
          <div v-if="squareChannelsExist">
            <v-card-subtitle class="pa-0">
              <v-icon color="success">mdi-check-circle</v-icon>
              You are ready to receive payments in your Square account
            </v-card-subtitle>
            <v-row class="mt-3 mb-n8">
              <v-col>
                <p>
                  Your Square account has been connected successfully. You will be
                  able to receive payments through Square.
                </p>
              </v-col>
            </v-row>
          </div>
          <div v-else>
            <v-card-subtitle class="pa-0">
              <v-icon color="error">mdi-alert-circle</v-icon>
              Square account not found.
            </v-card-subtitle>
            <v-row class="mt-3 mb-n8">
              <v-col>
                <p>
                  Please
                  <a href="restaurants">connect your Square account</a>
                  to your restaurant to receive payments.
                </p>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <div v-else-if="paymentProvider === 'stripe'">
        <v-card-subtitle class="pa-0">Connect with Stripe</v-card-subtitle>
        <div v-if="stripePlatformAccountSetup">
          <v-row class="mt-3">
            <v-col>
              <p>
                Please click the button below to set up your Stripe Connect account.
              </p>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-btn
              class="my-6 ml-3"
              color="#625aff"
              dark
              :loading="loading"
              @click="openStripeConnectWindow()"
            >
              + Add Stripe Connected Account
            </v-btn>
          </v-row>
        </div>
        <div v-else>
          <v-row class="mt-3">
            <v-col>
              <span>
                Please wait for your organization to set up Stripe so you can receive
                payments.
              </span>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-else-if="paymentProvider === 'square' && squareChannelsExist">
        <v-card-subtitle class="pa-0">
          You are ready to receive payments in your Square account
        </v-card-subtitle>
        <v-row class="mt-3">
          <v-col>
            <p>
              Your Square account has been connected successfully. You will be able
              to receive payments through Square.
            </p>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-card-subtitle class="pa-0">Square account not found.</v-card-subtitle>
        <v-row class="mt-3">
          <v-col>
            <p>
              Please
              <a href="restaurants">connect your Square account</a>
              to your restaurant to receive payments.
            </p>
          </v-col>
        </v-row>
      </div>
      <v-alert v-if="generalErrorMessage" type="error" outlined class="mt-4">
        {{ generalErrorMessage }}
      </v-alert>
    </v-card-text>
    <onboarding-footer :next="isValid" />
  </v-card>
</template>

<script>
import OnboardingFooter from "../OnboardingFooter.vue"
import { mapGetters, mapState } from "vuex"
import ChannelsMixins from "@/AuthenticatedContent/Settings/Channels/mixins.js"

export default {
  name: "onboarding-payments",
  components: { OnboardingFooter },
  mixins: [ChannelsMixins],
  data() {
    return {
      loading: false,
      isValid: false,
      paymentProvider: "stripe",
      hasStripeAccount: "",
      generalErrorMessage: "",
    }
  },
  computed: {
    ...mapState(["firebaseRefs", "channels", "settings", "currentUserRole"]),
    ...mapGetters(["currentOnboarding"]),
    stripePlatformAccountSetup() {
      return (
        this.channels.find(
          channel => channel.type === "stripe" && channel.platformAccount === true
        ) !== undefined
      )
    },
    squareChannelsExist() {
      return this.channels.find(channel => channel.posType === "square")
    },
  },
  mounted() {
    if (this.currentUserRole === "Admin") {
      if (!this.settings.singleBrandEnabled) {
        this.paymentProvider = "stripe"
        this.isValid = this.stripePlatformAccountSetup
      } else {
        if (this.stripePlatformAccountSetup) {
          this.paymentProvider = "stripe"
          this.isValid = true
        } else if (this.squareChannelsExist) {
          this.paymentProvider = "square"
          this.isValid = true
        }
      }
    }
  },
  methods: {
    async openStripeConnectWindow() {
      this.loading = true
      try {
        const response = await this.generateConnectedStripeAccount(
          this.currentOnboarding?.restaurantId
        )
        window.open(response.data.url)
        this.isValid = true
      } catch (error) {
        console.error("Error opening window", error)
        this.generalErrorMessage =
          "There was an issue connecting to Stripe. Please contact support if you continue experiencing this error."
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
a {
  color: #625aff;
  font-weight: bold;
  &:not(:hover) {
    text-decoration: none;
  }
}
</style>
